<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto text-center"
    >
      <img
        src="../../assets/images/pages/403.png"
        alt="Not
      authorized"
      />
      <h1
        class="mb-12 text-5xl"
        v-text="lang.errors.errorTitle.e403[languageSelected]"
      ></h1>
      <p
        class="mb-16"
        v-text="lang.errors.errorDescription.e403[languageSelected]"
      ></p>
      <vs-button
        size="large"
        to="/"
        v-text="lang.errors.backToHome[languageSelected]"
      ></vs-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NotAuthorized',
  computed: {
    ...mapState(['lang', 'languageSelected'])
  }
}
</script>
